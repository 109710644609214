var MailchimpController = new function(){
   var _ = this;
   var form 
   var msgSuccess;
   var msgError;

   function construct(){
      form = jQuery('.js-subscribe-form');
      msgSuccess = form.parent().find('.js-msg-success');
      msgError = form.parent().find('.js-msg-error');

      console.log(msgSuccess);

      form.on('submit', function ( event ) {
         event.preventDefault();
         _.submit()
      })
   }
   _.submit = function(){
      var input = form.find('input[type=email]');
      var email_address  = input.val();
      if ( email_address != '') { 
         input.removeClass('has-error');
         _.register(email_address);  
      }else{
         input.addClass('has-error');
      }
   }

   _.register = function(email) {
      _.clearMessage()
      jQuery.ajax({
              url: form.attr('action'), // proper url to your "store-address.php" file
              type: form.attr('method'), // <- IMPORTANT
              data: {
                 email_address: email
              },
              success: function(msg) {
                 console.log(msg);
                  var result = '';
                  if (msg == 200) {
                     _.showSuccessMessage()
                   } else {
                     _.showErrorMessage()
                  }
                 
             }, error: function(msg){
                jQuery('.js-msg-error').removeClass('hide'); 
             }
      });
   }

   _.showSuccessMessage = function(){
      msgSuccess.removeClass('hide');
   }

   _.showErrorMessage = function(){
      msgError.removeClass('hide'); 
   }

   _.clearMessage = function(){
      msgError.addClass('hide'); 
      msgSuccess.addClass('hide'); 
   }
   


   jQuery(document).ready(construct);
}
